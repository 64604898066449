import React from "react"
import { Col, Container, Row } from "react-bootstrap"
import Terms from "./style"
export default function TermsCondition() {
  return (
    <Terms>
      <Container>
        <Row className="justify-content-center">
          <Col className="col-xl-7 col-lg-8 col-md-11 text-center">
            <Terms.Box>
              <Terms.Title as="h2">TERMS OF USE</Terms.Title>
            </Terms.Box>
          </Col>
        </Row>
        <Row className="justify-content-center">
          <Col className="col-xxl-8 col-xl-9 col-lg-10">
            <Terms.Content>
              <Terms.Text>Last updated January 06, 2024</Terms.Text>
              <Terms.ContentBox>
                <Terms.TitleSmall as="h5">
                  AGREEMENT TO OUR LEGAL TERMS
                </Terms.TitleSmall>
                <Terms.Text>
                  These Terms of Use constitute a legally binding agreement made
                  between you, whether personally or on behalf of an entity
                  (“you”) and Monsai ("Company," “we," “us," or “our”),
                  concerning your access to and use of the{" "}
                  <a href="https://monsaiapp.com">https://monsaiapp.com</a>{" "}
                  website as well as any other media form, media channel, mobile
                  website or mobile application related, linked, or otherwise
                  connected thereto (collectively, the “Site”). You agree that
                  by accessing the Site, you have read, understood, and agreed
                  to be bound by all of these Terms of Use. IF YOU DO NOT AGREE
                  WITH ALL OF THESE TERMS OF USE, THEN YOU ARE EXPRESSLY
                  PROHIBITED FROM USING THE SITE AND YOU MUST DISCONTINUE USE
                  IMMEDIATELY.
                </Terms.Text>
                <Terms.Text>
                  Supplemental terms and conditions or documents that may be
                  posted on the Site from time to time are hereby expressly
                  incorporated herein by reference. We reserve the right, in our
                  sole discretion, to make changes or modifications to these
                  Terms of Use at any time and for any reason. We will alert you
                  about any changes by updating the “Last updated” date of these
                  Terms of Use, and you waive any right to receive specific
                  notice of each such change. Please ensure that you check the
                  applicable Terms every time you use our Site so that you
                  understand which Terms apply. You will be subject to, and will
                  be deemed to have been made aware of and to have accepted, the
                  changes in any revised Terms of Use by your continued use of
                  the Site after the date such revised Terms of Use are posted.
                </Terms.Text>
                <Terms.Text>
                  The information provided on the Site is not intended for
                  distribution to or use by any person or entity in any
                  jurisdiction or country where such distribution or use would
                  be contrary to law or regulation or which would subject us to
                  any registration requirement within such jurisdiction or
                  country. Accordingly, those persons who choose to access the
                  Site from other locations do so on their own initiative and
                  are solely responsible for compliance with local laws, if and
                  to the extent local laws are applicable.
                </Terms.Text>
                <Terms.Text>
                  The Site is not tailored to comply with industry-specific
                  regulations (Health Insurance Portability and Accountability
                  Act (HIPAA), Federal Information Security Management Act
                  (FISMA), etc.), so if your interactions would be subjected to
                  such laws, you may not use this Site. You may not use the Site
                  in a way that would violate the Gramm- Leach-Bliley Act
                  (GLBA).
                </Terms.Text>
                <Terms.Text>
                  The Services are intended for users who are at least 18 years
                  of age. All users who are minors in the jurisdiction in which
                  they reside (generally under the age of 18) must have the
                  permission of, and be directly supervised by, their parent or
                  guardian to use the Services. If you are a minor, you must
                  have your parent or guardian read and agree to these Legal
                  Terms prior to you using the Services.
                </Terms.Text>
              </Terms.ContentBox>
              <Terms.ContentBox id="toc">
                <Terms.TitleSmall as="h5">TABLE OF CONTENTS</Terms.TitleSmall>
                <Terms.List>
                  <Terms.Text>
                    <a href="#services">1. OUR SERVICES</a>
                  </Terms.Text>
                  <Terms.Text>
                    <a href="#ip">2. INTELLECTUAL PROPERTY RIGHTS</a>
                  </Terms.Text>
                  <Terms.Text>
                    <a href="#userreps">3. USER REPRESENTATIONS</a>
                  </Terms.Text>
                  <Terms.Text>
                    <a href="#userreg">4. USER REGISTRATION</a>
                  </Terms.Text>
                  <Terms.Text>
                    <a href="#prohibited">5. PROHIBITED ACTIVITIES</a>
                  </Terms.Text>
                  <Terms.Text>
                    <a href="#ugc">6. USER GENERATED CONTRIBUTIONS</a>
                  </Terms.Text>
                  <Terms.Text>
                    <a href="#license">7. CONTRIBUTION LICENSE</a>
                  </Terms.Text>
                  <Terms.Text>
                    <a href="#reviews">8. GUIDELINES FOR REVIEWS</a>
                  </Terms.Text>
                  <Terms.Text>
                    <a href="#mobile">9. MOBILE APPLICATION LICENSE</a>
                  </Terms.Text>
                  <Terms.Text>
                    <a href="#thirdparty">
                      10. THIRD-PARTY WEBSITES AND CONTENT
                    </a>
                  </Terms.Text>
                  <Terms.Text>
                    <a href="#sitemanage">11. SERVICES MANAGEMENT</a>
                  </Terms.Text>
                  <Terms.Text>
                    <a href="#ppyes">12. PRIVACY POLICY</a>
                  </Terms.Text>
                  <Terms.Text>
                    <a href="#terms">13. TERM AND TERMINATION</a>
                  </Terms.Text>
                  <Terms.Text>
                    <a href="#modifications">
                      14. MODIFICATIONS AND INTERRUPTIONS
                    </a>
                  </Terms.Text>
                  <Terms.Text>
                    <a href="#law">15. GOVERNING LAW</a>
                  </Terms.Text>
                  <Terms.Text>
                    <a href="#disputes">16. DISPUTE RESOLUTION</a>
                  </Terms.Text>
                  <Terms.Text>
                    <a href="#corrections">17. CORRECTIONS</a>
                  </Terms.Text>
                  <Terms.Text>
                    <a href="#disclaimer">18. DISCLAIMER</a>
                  </Terms.Text>
                  <Terms.Text>
                    <a href="#liability">19. LIMITATIONS OF LIABILITY</a>
                  </Terms.Text>
                  <Terms.Text>
                    <a href="#indemnification">20. INDEMNIFICATION</a>
                  </Terms.Text>
                  <Terms.Text>
                    <a href="#userdata">21. USER DATA</a>
                  </Terms.Text>
                  <Terms.Text>
                    <a href="#electronic">
                      22. ELECTRONIC COMMUNICATIONS, TRANSACTIONS, AND
                      SIGNATURES
                    </a>
                  </Terms.Text>
                  <Terms.Text>
                    <a href="#california">23. CALIFORNIA USERS AND RESIDENTS</a>
                  </Terms.Text>
                  <Terms.Text>
                    <a href="#miscellaneous">24. MISCELLANEOUS</a>
                  </Terms.Text>
                  <Terms.Text>
                    <a href="#contact">25. CONTACT US</a>
                  </Terms.Text>
                </Terms.List>
              </Terms.ContentBox>
              <Terms.ContentBox id="services">
                <Terms.TitleSmall as="h5">1. OUR SERVICES</Terms.TitleSmall>
                <Terms.Text>
                  The information provided when using the Services is not
                  intended for distribution to or use by any person or entity in
                  any jurisdiction or country where such distribution or use
                  would be contrary to law or regulation or which would subject
                  us to any registration requirement within such jurisdiction or
                  country. Accordingly, those persons who choose to access the
                  Services from other locations do so on their own initiative
                  and are solely responsible for compliance with local laws, if
                  and to the extent local laws are applicable.
                </Terms.Text>
                <Terms.Text>
                  The Services are not tailored to comply with industry-specific
                  regulations (Health Insurance Portability and Accountability
                  Act (HIPAA), Federal Information Security Management Act
                  (FISMA), etc.), so if your interactions would be subjected to
                  such laws, you may not use the Services. You may not use the
                  Services in a way that would violate the Gramm-Leach-Bliley
                  Act (GLBA).
                </Terms.Text>
              </Terms.ContentBox>
              <Terms.ContentBox id="ip">
                <Terms.TitleSmall as="h5">
                  2. INTELLECTUAL PROPERTY RIGHTS
                </Terms.TitleSmall>
                <Terms.Subtitle>Our intellectual property</Terms.Subtitle>
                <Terms.Text>
                  We are the owner or the licensee of all intellectual property
                  rights in our Services, including all source code, databases,
                  functionality, software, website designs, audio, video, text,
                  photographs, and graphics in the Services (collectively, the
                  "Content"), as well as the trademarks, service marks, and
                  logos contained therein (the "Marks").
                </Terms.Text>
                <Terms.Text>
                  Our Content and Marks are protected by copyright and trademark
                  laws (and various other intellectual property rights and
                  unfair competition laws) and treaties in the United States and
                  around the world.
                </Terms.Text>
                <Terms.Text>
                  The Content and Marks are provided in or through the Services
                  "AS IS" for your personal, non-commercial use or internal
                  business purpose only.
                </Terms.Text>
                <Terms.Subtitle>Your use of our Services</Terms.Subtitle>
                <Terms.Text>
                  Subject to your compliance with these Legal Terms, including
                  the "<a href="#prohibited">PROHIBITED ACTIVITIES</a>" section
                  below, we grant you a non-exclusive, non-transferable,
                  revocable license to:
                </Terms.Text>
                <Terms.List>
                  <Terms.ListItem>access the Services; and</Terms.ListItem>
                  <Terms.ListItem>
                    download or print a copy of any portion of the Content to
                    which you have properly gained access.
                  </Terms.ListItem>
                </Terms.List>
                <Terms.Text>
                  solely for your personal, non-commercial use or internal
                  business purpose.
                </Terms.Text>
                <Terms.Text>
                  Except as set out in this section or elsewhere in our Legal
                  Terms, no part of the Services and no Content or Marks may be
                  copied, reproduced, aggregated, republished, uploaded, posted,
                  publicly displayed, encoded, translated, transmitted,
                  distributed, sold, licensed, or otherwise exploited for any
                  commercial purpose whatsoever, without our express prior
                  written permission.
                </Terms.Text>
                <Terms.Text>
                  If you wish to make any use of the Services, Content, or Marks
                  other than as set out in this section or elsewhere in our
                  Legal Terms, please address your request to:
                  support@monsaiapp.com. If we ever grant you the permission to
                  post, reproduce, or publicly display any part of our Services
                  or Content, you must identify us as the owners or licensors of
                  the Services, Content, or Marks and ensure that any copyright
                  or proprietary notice appears or is visible on posting,
                  reproducing, or displaying our Content.
                </Terms.Text>
                <Terms.Text>
                  We reserve all rights not expressly granted to you in and to
                  the Services, Content, and Marks.
                </Terms.Text>
                <Terms.Text>
                  Any breach of these Intellectual Property Rights will
                  constitute a material breach of our Legal Terms and your right
                  to use our Services will terminate immediately.
                </Terms.Text>
                <Terms.Subtitle>Your submissions</Terms.Subtitle>
                <Terms.Text>
                  Please review this section and the "
                  <a href="#prohibited">PROHIBITED ACTIVITIES</a>" section
                  carefully prior to using our Services to understand the (a)
                  rights you give us and (b) obligations you have when you post
                  or upload any content through the Services.
                </Terms.Text>
                <Terms.Text>
                  Submissions: By directly sending us any question, comment,
                  suggestion, idea, feedback, or other information about the
                  Services ("Submissions"), you agree to assign to us all
                  intellectual property rights in such Submission. You agree
                  that we shall own this Submission and be entitled to its
                  unrestricted use and dissemination for any lawful purpose,
                  commercial or otherwise, without acknowledgment or
                  compensation to you.
                </Terms.Text>
                <Terms.Text>
                  You are responsible for what you post or upload: By sending us
                  Submissions through any part of the Services you:
                </Terms.Text>
                <Terms.List>
                  <Terms.ListItem>
                    confirm that you have read and agree with our "
                    <a href="#prohibited">PROHIBITED ACTIVITIES</a>" and will
                    not post, send, publish, upload, or transmit through the
                    Services any Submission that is illegal, harassing, hateful,
                    harmful, defamatory, obscene, bullying, abusive,
                    discriminatory, threatening to any person or group, sexually
                    explicit, false, inaccurate, deceitful, or misleading;
                  </Terms.ListItem>
                  <Terms.ListItem>
                    to the extent permissible by applicable law, waive any and
                    all moral rights to any such Submission;
                  </Terms.ListItem>
                  <Terms.ListItem>
                    warrant that any such Submission are original to you or that
                    you have the necessary rights and licenses to submit such
                    Submissions and that you have full authority to grant us the
                    above-mentioned rights in relation to your Submissions; and
                  </Terms.ListItem>
                  <Terms.ListItem>
                    warrant and represent that your Submissions do not
                    constitute confidential information.
                  </Terms.ListItem>
                </Terms.List>
                <Terms.Text>
                  You are solely responsible for your Submissions and you
                  expressly agree to reimburse us for any and all losses that we
                  may suffer because of your breach of (a) this section, (b) any
                  third party’s intellectual property rights, or (c) applicable
                  law.
                </Terms.Text>
              </Terms.ContentBox>
              <Terms.ContentBox id="userreps">
                <Terms.TitleSmall as="h5">
                  3. USER REPRESENTATIONS
                </Terms.TitleSmall>
                <Terms.Text>
                  By using the Site, you represent and warrant that: (1) all
                  registration information you submit will be true, accurate,
                  current, and complete; (2) you will maintain the accuracy of
                  such information and promptly update such registration
                  information as necessary; (3) you have the legal capacity and
                  you agree to comply with these Terms of Use; (4) you are not a
                  minor in the jurisdiction in which you reside; (5) you will
                  not access the Site through automated or non-human means,
                  whether through a bot, script, or otherwise; (6) you will not
                  use the Site for any illegal or unauthorized purpose; and (7)
                  your use of the Site will not violate any applicable law or
                  regulation.
                </Terms.Text>
                <Terms.Text>
                  If you provide any information that is untrue, inaccurate, not
                  current, or incomplete, we have the right to suspend or
                  terminate your account and refuse any and all current or
                  future use of the Site (or any portion thereof).
                </Terms.Text>
              </Terms.ContentBox>
              <Terms.ContentBox id="userreg">
                <Terms.TitleSmall as="h5">
                  4. USER REGISTRATION
                </Terms.TitleSmall>
                <Terms.Text>
                  You may be required to register with the Site. You agree to
                  keep your password confidential and will be responsible for
                  all use of your account and password. We reserve the right to
                  remove, reclaim, or change a username you select if we
                  determine, in our sole discretion, that such username is
                  inappropriate, obscene, or otherwise objectionable.
                </Terms.Text>
              </Terms.ContentBox>
              <Terms.ContentBox id="prohibited">
                <Terms.TitleSmall as="h5">
                  5. PROHIBITED ACTIVITIES
                </Terms.TitleSmall>
                <Terms.Text>
                  You may not access or use the Site for any purpose other than
                  that for which we make the Site available. The Site may not be
                  used in connection with any commercial endeavors except those
                  that are specifically endorsed or approved by us.
                </Terms.Text>
                <Terms.Text>
                  As a user of the Site, you agree not to:
                </Terms.Text>
                <Terms.List>
                  <Terms.ListItem>
                    Share offensive, pornographic, or violent content.
                  </Terms.ListItem>
                  <Terms.ListItem>
                    Impersonate somebody else or post their photos without their
                    consent.
                  </Terms.ListItem>
                  <Terms.ListItem>
                    Use the Site for illegal purposes.
                  </Terms.ListItem>
                  <Terms.ListItem>
                    Share content that promotes hate speech, bullying,
                    discrimination, harassment, violence, abuse.
                  </Terms.ListItem>
                  <Terms.ListItem>
                    Violate our Community Guidelines.
                  </Terms.ListItem>
                  <Terms.ListItem>
                    Systematically retrieve data or other content from the Site
                    to create or compile, directly or indirectly, a collection,
                    compilation, database, or directory without written
                    permission from us.
                  </Terms.ListItem>
                  <Terms.ListItem>
                    Trick, defraud, or mislead us and other users, especially in
                    any attempt to learn sensitive account information such as
                    user passwords.
                  </Terms.ListItem>
                  <Terms.ListItem>
                    Circumvent, disable, or otherwise interfere with
                    security-related features of the Site, including features
                    that prevent or restrict the use or copying of any Content
                    or enforce limitations on the use of the Site and/or the
                    Content contained therein.
                  </Terms.ListItem>
                  <Terms.ListItem>
                    Disparage, tarnish, or otherwise harm, in our opinion, us
                    and/or the Site.
                  </Terms.ListItem>
                  <Terms.ListItem>
                    Use any information obtained from the Site in order to
                    harass, abuse, or harm another person.
                  </Terms.ListItem>
                  <Terms.ListItem>
                    Make improper use of our support services or submit false
                    reports of abuse or misconduct.
                  </Terms.ListItem>
                  <Terms.ListItem>
                    Use the Site in a manner inconsistent with any applicable
                    laws or regulations.
                  </Terms.ListItem>
                  <Terms.ListItem>
                    Engage in unauthorized framing of or linking to the Site.
                  </Terms.ListItem>
                  <Terms.ListItem>
                    Upload or transmit (or attempt to upload or to transmit)
                    viruses, Trojan horses, or other material, including
                    excessive use of capital letters and spamming (continuous
                    posting of repetitive text), that interferes with any
                    party’s uninterrupted use and enjoyment of the Site or
                    modifies, impairs, disrupts, alters, or interferes with the
                    use, features, functions, operation, or maintenance of the
                    Site.
                  </Terms.ListItem>
                  <Terms.ListItem>
                    Engage in any automated use of the system, such as using
                    scripts to send comments or messages, or using any data
                    mining, robots, or similar data gathering and extraction
                    tools.
                  </Terms.ListItem>
                  <Terms.ListItem>
                    Delete the copyright or other proprietary rights notice from
                    any Content.
                  </Terms.ListItem>
                  <Terms.ListItem>
                    Attempt to impersonate another user or person or use the
                    username of another user.
                  </Terms.ListItem>
                  <Terms.ListItem>
                    Upload or transmit (or attempt to upload or to transmit) any
                    material that acts as a passive or active information
                    collection or transmission mechanism, including without
                    limitation, clear graphics interchange formats (“gifs”), 1×1
                    pixels, web bugs, cookies, or other similar devices
                    (sometimes referred to as “spyware” or “passive collection
                    mechanisms” or “pcms”).
                  </Terms.ListItem>
                  <Terms.ListItem>
                    Interfere with, disrupt, or create an undue burden on the
                    Site or the networks or services connected to the Site.
                  </Terms.ListItem>
                  <Terms.ListItem>
                    Harass, annoy, intimidate, or threaten any of our employees
                    or agents engaged in providing any portion of the Site to
                    you.
                  </Terms.ListItem>
                  <Terms.ListItem>
                    Attempt to bypass any measures of the Site designed to
                    prevent or restrict access to the Site, or any portion of
                    the Site.
                  </Terms.ListItem>
                  <Terms.ListItem>
                    Copy or adapt the Site’s software, including but not limited
                    to Flash, PHP, HTML, JavaScript, or other code.
                  </Terms.ListItem>
                  <Terms.ListItem>
                    Except as permitted by applicable law, decipher, decompile,
                    disassemble, or reverse engineer any of the software
                    comprising or in any way making up a part of the Site.
                  </Terms.ListItem>
                  <Terms.ListItem>
                    Except as may be the result of standard search engine or
                    Internet browser usage, use, launch, develop, or distribute
                    any automated system, including without limitation, any
                    spider, robot, cheat utility, scraper, or offline reader
                    that accesses the Site, or using or launching any
                    unauthorized script or other software.
                  </Terms.ListItem>
                  <Terms.ListItem>
                    Use a buying agent or purchasing agent to make purchases on
                    the Site.
                  </Terms.ListItem>
                  <Terms.ListItem>
                    Make any unauthorized use of the Site, including collecting
                    usernames and/or email addresses of users by electronic or
                    other means for the purpose of sending unsolicited email, or
                    creating user accounts by automated means or under false
                    pretenses.
                  </Terms.ListItem>
                  <Terms.ListItem>
                    Use the Site as part of any effort to compete with us or
                    otherwise use the Site and/or the Content for any
                    revenue-generating endeavor or commercial enterprise.
                  </Terms.ListItem>
                  <Terms.ListItem>
                    Use the Site to advertise or offer to sell goods and
                    services.
                  </Terms.ListItem>
                  <Terms.ListItem>
                    Sell or otherwise transfer your profile.
                  </Terms.ListItem>
                </Terms.List>
              </Terms.ContentBox>
              <Terms.ContentBox id="ugc">
                <Terms.TitleSmall as="h5">
                  6. USER GENERATED CONTRIBUTIONS
                </Terms.TitleSmall>
                <Terms.Text>
                  The Services does not offer users to submit or post content.
                  We may provide you with the opportunity to create, submit,
                  post, display, transmit, perform, publish, distribute, or
                  broadcast content and materials to us or on the Services,
                  including but not limited to text, writings, video, audio,
                  photographs, graphics, comments, suggestions, or personal
                  information or other material (collectively, "Contributions").
                  Contributions may be viewable by other users of the Services
                  and through third-party websites. As such, any Contributions
                  you transmit may be treated in accordance with the Services'
                  Privacy Policy. When you create or make available any
                  Contributions, you thereby represent and warrant that:
                </Terms.Text>
                <Terms.List>
                  <Terms.ListItem>
                    The creation, distribution, transmission, public display, or
                    performance, and the accessing, downloading, or copying of
                    your Contributions do not and will not infringe the
                    proprietary rights, including but not limited to the
                    copyright, patent, trademark, trade secret, or moral rights
                    of any third party.
                  </Terms.ListItem>
                  <Terms.ListItem>
                    You are the creator and owner of or have the necessary
                    licenses, rights, consents, releases, and permissions to use
                    and to authorize us, the Site, and other users of the Site
                    to use your Contributions in any manner contemplated by the
                    Site and these Terms of Use.
                  </Terms.ListItem>
                  <Terms.ListItem>
                    You have the written consent, release, and/or permission of
                    each and every identifiable individual person in your
                    Contributions to use the name or likeness of each and every
                    such identifiable individual person to enable inclusion and
                    use of your Contributions in any manner contemplated by the
                    Site and these Terms of Use.
                  </Terms.ListItem>
                  <Terms.ListItem>
                    Your Contributions are not false, inaccurate, or misleading.
                  </Terms.ListItem>
                  <Terms.ListItem>
                    Your Contributions are not unsolicited or unauthorized
                    advertising, promotional materials, pyramid schemes, chain
                    letters, spam, mass mailings, or other forms of
                    solicitation.
                  </Terms.ListItem>
                  <Terms.ListItem>
                    Your Contributions are not obscene, lewd, lascivious,
                    filthy, violent, harassing, libelous, slanderous, or
                    otherwise objectionable (as determined by us).
                  </Terms.ListItem>
                  <Terms.ListItem>
                    Your Contributions do not ridicule, mock, disparage,
                    intimidate, or abuse anyone.
                  </Terms.ListItem>
                  <Terms.ListItem>
                    Your Contributions are not used to harass or threaten (in
                    the legal sense of those terms) any other person and to
                    promote violence against a specific person or class of
                    people.
                  </Terms.ListItem>
                  <Terms.ListItem>
                    Your Contributions do not violate any applicable law,
                    regulation, or rule.
                  </Terms.ListItem>
                  <Terms.ListItem>
                    Your Contributions do not violate the privacy or publicity
                    rights of any third party.
                  </Terms.ListItem>
                  <Terms.ListItem>
                    Your Contributions do not violate any applicable law
                    concerning child pornography, or otherwise intended to
                    protect the health or well-being of minors.
                  </Terms.ListItem>
                  <Terms.ListItem>
                    Your Contributions do not include any offensive comments
                    that are connected to race, national origin, gender, sexual
                    preference, or physical handicap.
                  </Terms.ListItem>
                  <Terms.ListItem>
                    Your Contributions do not otherwise violate, or link to
                    material that violates, any provision of these Terms of Use,
                    or any applicable law or regulation.
                  </Terms.ListItem>
                </Terms.List>
                <Terms.Text>
                  Any use of the Site in violation of the foregoing violates
                  these Terms of Use and may result in, among other things,
                  termination or suspension of your rights to use the Site.
                </Terms.Text>
              </Terms.ContentBox>
              <Terms.ContentBox id="license">
                <Terms.TitleSmall as="h5">
                  7. CONTRIBUTION LICENSE
                </Terms.TitleSmall>
                <Terms.Text>
                  You and Services agree that we may access, store, process, and
                  use any information and personal data that you provide
                  following the terms of the Privacy Policy and your choices
                  (including settings).
                </Terms.Text>
                <Terms.Text>
                  By submitting suggestions or other feedback regarding the
                  Services, you agree that we can use and share such feedback
                  for any purpose without compensation to you.
                </Terms.Text>
                <Terms.Text>
                  We do not assert any ownership over your Contributions. You
                  retain full ownership of all of your Contributions and any
                  intellectual property rights or other proprietary rights
                  associated with your Contributions. We are not liable for any
                  statements or representations in your Contributions provided
                  by you in any area on the Services. You are solely responsible
                  for your Contributions to the Services and you expressly agree
                  to exonerate us from any and all responsibility and to refrain
                  from any legal action against us regarding your Contributions.
                </Terms.Text>
              </Terms.ContentBox>
              <Terms.ContentBox id="reviews">
                <Terms.TitleSmall as="h5">
                  8. GUIDELINES FOR REVIEWS
                </Terms.TitleSmall>
                <Terms.Text>
                  We may provide you areas on the Site to leave reviews or
                  ratings. When posting a review, you must comply with the
                  following criteria: (1) you should have firsthand experience
                  with the person/entity being reviewed; (2) your reviews should
                  not contain offensive profanity, or abusive, racist,
                  offensive, or hate language; (3) your reviews should not
                  contain discriminatory references based on religion, race,
                  gender, national origin, age, marital status, sexual
                  orientation, or disability; (4) your reviews should not
                  contain references to illegal activity; (5) you should not be
                  affiliated with competitors if posting negative reviews; (6)
                  you should not make any conclusions as to the legality of
                  conduct; (7) you may not post any false or misleading
                  statements; and (8) you may not organize a campaign
                  encouraging others to post reviews, whether positive or
                  negative.
                </Terms.Text>
                <Terms.Text>
                  We may accept, reject, or remove reviews in our sole
                  discretion. We have absolutely no obligation to screen reviews
                  or to delete reviews, even if anyone considers reviews
                  objectionable or inaccurate. Reviews are not endorsed by us,
                  and do not necessarily represent our opinions or the views of
                  any of our affiliates or partners. We do not assume liability
                  for any review or for any claims, liabilities, or losses
                  resulting from any review. By posting a review, you hereby
                  grant to us a perpetual, non- exclusive, worldwide,
                  royalty-free, fully-paid, assignable, and sublicensable right
                  and license to reproduce, modify, translate, transmit by any
                  means, display, perform, and/or distribute all content
                  relating to reviews.
                </Terms.Text>
              </Terms.ContentBox>
              <Terms.ContentBox id="mobile">
                <Terms.TitleSmall as="h5">
                  9. MOBILE APPLICATION LICENSE
                </Terms.TitleSmall>
                <Terms.Subtitle>Use License</Terms.Subtitle>
                <Terms.Text>
                  If you access the Site via a mobile application, then we grant
                  you a revocable, non- exclusive, non-transferable, limited
                  right to install and use the mobile application on wireless
                  electronic devices owned or controlled by you, and to access
                  and use the mobile application on such devices strictly in
                  accordance with the terms and conditions of this mobile
                  application license contained in these Terms of Use. You shall
                  not: (1) except as permitted by applicable law, decompile,
                  reverse engineer, disassemble, attempt to derive the source
                  code of, or decrypt the application; (2) make any
                  modification, adaptation, improvement, enhancement,
                  translation, or derivative work from the application; (3)
                  violate any applicable laws, rules, or regulations in
                  connection with your access or use of the application; (4)
                  remove, alter, or obscure any proprietary notice (including
                  any notice of copyright or trademark) posted by us or the
                  licensors of the application; (5) use the application for any
                  revenue generating endeavor, commercial enterprise, or other
                  purpose for which it is not designed or intended; (6) make the
                  application available over a network or other environment
                  permitting access or use by multiple devices or users at the
                  same time; (7) use the application for creating a product,
                  service, or software that is, directly or indirectly,
                  competitive with or in any way a substitute for the
                  application; (8) use the application to send automated queries
                  to any website or to send any unsolicited commercial e-mail;
                  or (9) use any proprietary information or any of our
                  interfaces or our other intellectual property in the design,
                  development, manufacture, licensing, or distribution of any
                  applications, accessories, or devices for use with the
                  application.
                </Terms.Text>
                <Terms.Subtitle>Apple and Android Devices</Terms.Subtitle>
                <Terms.Text>
                  The following terms apply when you use a mobile application
                  obtained from either the Apple Store or Google Play (each an
                  “App Distributor”) to access the Site: (1) the license granted
                  to you for our mobile application is limited to a
                  non-transferable license to use the application on a device
                  that utilizes the Apple iOS or Android operating systems, as
                  applicable, and in accordance with the usage rules set forth
                  in the applicable App Distributor’s terms of service; (2) we
                  are responsible for providing any maintenance and support
                  services with respect to the mobile application as specified
                  in the terms and conditions of this mobile application license
                  contained in these Terms of Use or as otherwise required under
                  applicable law, and you acknowledge that each App Distributor
                  has no obligation whatsoever to furnish any maintenance and
                  support services with respect to the mobile application; (3)
                  in the event of any failure of the mobile application to
                  conform to any applicable warranty, you may notify the
                  applicable App Distributor, and the App Distributor, in
                  accordance with its terms and policies, may refund the
                  purchase price, if any, paid for the mobile application, and
                  to the maximum extent permitted by applicable law, the App
                  Distributor will have no other warranty obligation whatsoever
                  with respect to the mobile application; (4) you represent and
                  warrant that (i) you are not located in a country that is
                  subject to a U.S. government embargo, or that has been
                  designated by the U.S. government as a “terrorist supporting”
                  country and (ii) you are not listed on any U.S. government
                  list of prohibited or restricted parties; (5) you must comply
                  with applicable third-party terms of agreement when using the
                  mobile application, e.g., if you have a VoIP application, then
                  you must not be in violation of their wireless data service
                  agreement when using the mobile application; and (6) you
                  acknowledge and agree that the App Distributors are
                  third-party beneficiaries of the terms and conditions in this
                  mobile application license contained in these Terms of Use,
                  and that each App Distributor will have the right (and will be
                  deemed to have accepted the right) to enforce the terms and
                  conditions in this mobile application license contained in
                  these Terms of Use against you as a third-party beneficiary
                  thereof.
                </Terms.Text>
              </Terms.ContentBox>
              <Terms.ContentBox id="thirdparty">
                <Terms.TitleSmall as="h5">
                  10. THIRD-PARTY WEBSITE AND CONTENT
                </Terms.TitleSmall>
                <Terms.Text>
                  The Site may contain (or you may be sent via the Site) links
                  to other websites ("Third- Party Websites") as well as
                  articles, photographs, text, graphics, pictures, designs,
                  music, sound, video, information, applications, software, and
                  other content or items belonging to or originating from third
                  parties ("Third-Party Content"). Such Third-Party Websites and
                  Third-Party Content are not investigated, monitored, or
                  checked for accuracy, appropriateness, or completeness by us,
                  and we are not responsible for any Third-Party Websites
                  accessed through the Site or any Third-Party Content posted
                  on, available through, or installed from the Site, including
                  the content, accuracy, offensiveness, opinions, reliability,
                  privacy practices, or other policies of or contained in the
                  Third-Party Websites or the Third-Party Content. Inclusion of,
                  linking to, or permitting the use or installation of any
                  Third-Party Websites or any Third-Party Content does not imply
                  approval or endorsement thereof by us. If you decide to leave
                  the Site and access the Third-Party Websites or to use or
                  install any Third-Party Content, you do so at your own risk,
                  and you should be aware these Terms of Use no longer govern.
                  You should review the applicable terms and policies, including
                  privacy and data gathering practices, of any website to which
                  you navigate from the Site or relating to any applications you
                  use or install from the Site. Any purchases you make through
                  Third-Party Websites will be through other websites and from
                  other companies, and we take no responsibility whatsoever in
                  relation to such purchases which are exclusively between you
                  and the applicable third party. You agree and acknowledge that
                  we do not endorse the products or services offered on
                  Third-Party Websites and you shall hold us harmless from any
                  harm caused by your purchase of such products or services.
                  Additionally, you shall hold us harmless from any losses
                  sustained by you or harm caused to you relating to or
                  resulting in any way from any Third-Party Content or any
                  contact with Third-Party Websites.
                </Terms.Text>
              </Terms.ContentBox>
              <Terms.ContentBox id="sitemanage">
                <Terms.TitleSmall as="h5">
                  11. SERVICES MANAGEMENT
                </Terms.TitleSmall>
                <Terms.Text>
                  We reserve the right, but not the obligation, to: (1) monitor
                  the Site for violations of these Terms of Use; (2) take
                  appropriate legal action against anyone who, in our sole
                  discretion, violates the law or these Terms of Use, including
                  without limitation, reporting such user to law enforcement
                  authorities; (3) in our sole discretion and without
                  limitation, refuse, restrict access to, limit the availability
                  of, or disable (to the extent technologically feasible) any of
                  your Contributions or any portion thereof; (4) in our sole
                  discretion and without limitation, notice, or liability, to
                  remove from the Site or otherwise disable all files and
                  content that are excessive in size or are in any way
                  burdensome to our systems; and (5) otherwise manage the Site
                  in a manner designed to protect our rights and property and to
                  facilitate the proper functioning of the Site.
                </Terms.Text>
              </Terms.ContentBox>
              <Terms.ContentBox id="ppyes">
                <Terms.TitleSmall as="h5">12. PRIVACY POLICY</Terms.TitleSmall>
                <Terms.Text>
                  We care about data privacy and security. Please review our
                  Privacy Policy:{" "}
                  <a href="https://monsaiapp.com/privacy/">
                    https://monsaiapp.com/privacy
                  </a>
                  . By using the Site, you agree to be bound by our Privacy
                  Policy, which is incorporated into these Terms of Use. Please
                  be advised the Site is hosted in the United States. If you
                  access the Site from any other region of the world with laws
                  or other requirements governing personal data collection, use,
                  or disclosure that differ from applicable laws in the United
                  States, then through your continued use of the Site, you are
                  transferring your data to the United States, and you agree to
                  have your data transferred to and processed in the United
                  States.
                </Terms.Text>
              </Terms.ContentBox>
              <Terms.ContentBox id="terms">
                <Terms.TitleSmall as="h5">
                  13. TERM AND TERMINATION
                </Terms.TitleSmall>
                <Terms.Text>
                  These Terms of Use shall remain in full force and effect while
                  you use the Site. WITHOUT LIMITING ANY OTHER PROVISION OF
                  THESE TERMS OF USE, WE RESERVE THE RIGHT TO, IN OUR SOLE
                  DISCRETION AND WITHOUT NOTICE OR LIABILITY, DENY ACCESS TO AND
                  USE OF THE SITE (INCLUDING BLOCKING CERTAIN IP ADDRESSES), TO
                  ANY PERSON FOR ANY REASON OR FOR NO REASON, INCLUDING WITHOUT
                  LIMITATION FOR BREACH OF ANY REPRESENTATION, WARRANTY, OR
                  COVENANT CONTAINED IN THESE TERMS OF USE OR OF ANY APPLICABLE
                  LAW OR REGULATION. WE MAY TERMINATE YOUR USE OR PARTICIPATION
                  IN THE SITE OR DELETE YOUR ACCOUNT AND ANY CONTENT OR
                  INFORMATION THAT YOU POSTED AT ANY TIME, WITHOUT WARNING, IN
                  OUR SOLE DISCRETION.
                </Terms.Text>
                <Terms.Text>
                  If we terminate or suspend your account for any reason, you
                  are prohibited from registering and creating a new account
                  under your name, a fake or borrowed name, or the name of any
                  third party, even if you may be acting on behalf of the third
                  party. In addition to terminating or suspending your account,
                  we reserve the right to take appropriate legal action,
                  including without limitation pursuing civil, criminal, and
                  injunctive redress.
                </Terms.Text>
              </Terms.ContentBox>
              <Terms.ContentBox id="modifications">
                <Terms.TitleSmall as="h5">
                  14. MODIFICATIONS AND INTERRUPTIONS
                </Terms.TitleSmall>
                <Terms.Text>
                  We reserve the right to change, modify, or remove the contents
                  of the Site at any time or for any reason at our sole
                  discretion without notice. However, we have no obligation to
                  update any information on our Site. We also reserve the right
                  to modify or discontinue all or part of the Site without
                  notice at any time. We will not be liable to you or any third
                  party for any modification, price change, suspension, or
                  discontinuance of the Site.
                </Terms.Text>
                <Terms.Text>
                  We cannot guarantee the Site will be available at all times.
                  We may experience hardware, software, or other problems or
                  need to perform maintenance related to the Site, resulting in
                  interruptions, delays, or errors. We reserve the right to
                  change, revise, update, suspend, discontinue, or otherwise
                  modify the Site at any time or for any reason without notice
                  to you. You agree that we have no liability whatsoever for any
                  loss, damage, or inconvenience caused by your inability to
                  access or use the Site during any downtime or discontinuance
                  of the Site. Nothing in these Terms of Use will be construed
                  to obligate us to maintain and support the Site or to supply
                  any corrections, updates, or releases in connection therewith.
                </Terms.Text>
              </Terms.ContentBox>
              <Terms.ContentBox id="law">
                <Terms.TitleSmall as="h5">15. GOVERNING LAW</Terms.TitleSmall>
                <Terms.Text>
                  These Terms of Use and your use of the Site are governed by
                  and construed in accordance with the laws of the Commonwealth
                  of Pennsylvania applicable to agreements made and to be
                  entirely performed within the Commonwealth of Pennsylvania,
                  without regard to its conflict of law principles.
                </Terms.Text>
              </Terms.ContentBox>
              <Terms.ContentBox id="disputes">
                <Terms.TitleSmall as="h5">
                  16. DISPUTE RESOLUTION
                </Terms.TitleSmall>
                <Terms.Subtitle>Informal Negotiations</Terms.Subtitle>
                <Terms.Text>
                  To expedite resolution and control the cost of any dispute,
                  controversy, or claim related to these Terms of Use (each
                  "Dispute" and collectively, the “Disputes”) brought by either
                  you or us (individually, a “Party” and collectively, the
                  “Parties”), the Parties agree to first attempt to negotiate
                  any Dispute (except those Disputes expressly provided below)
                  informally for at least thirty (30) days before initiating
                  arbitration. Such informal negotiations commence upon written
                  notice from one Party to the other Party.
                </Terms.Text>
                <Terms.Subtitle>Binding Arbitration</Terms.Subtitle>
                <Terms.Text>
                  If the Parties are unable to resolve a Dispute through
                  informal negotiations, the Dispute (except those Disputes
                  expressly excluded below) will be finally and exclusively
                  resolved by binding arbitration. YOU UNDERSTAND THAT WITHOUT
                  THIS PROVISION, YOU WOULD HAVE THE RIGHT TO SUE IN COURT AND
                  HAVE A JURY TRIAL. The arbitration shall be commenced and
                  conducted under the Commercial Arbitration Rules of the
                  American Arbitration Association ("AAA") and, where
                  appropriate, the AAA’s Supplementary Procedures for Consumer
                  Related Disputes ("AAA Consumer Rules"), both of which are
                  available at the{" "}
                  <a href="http://www.adr.org">
                    American Arbitration Association (AAA) website
                  </a>
                  . Your arbitration fees and your share of arbitrator
                  compensation shall be governed by the AAA Consumer Rules and,
                  where appropriate, limited by the AAA Consumer Rules. The
                  arbitration may be conducted in person, through the submission
                  of documents, by phone, or online. The arbitrator will make a
                  decision in writing, but need not provide a statement of
                  reasons unless requested by either Party. The arbitrator must
                  follow applicable law, and any award may be challenged if the
                  arbitrator fails to do so. Except where otherwise required by
                  the applicable AAA rules or applicable law, the arbitration
                  will take place in Philadelphia, Pennsylvania. Except as
                  otherwise provided herein, the Parties may litigate in court
                  to compel arbitration, stay proceedings pending arbitration,
                  or to confirm, modify, vacate, or enter judgment on the award
                  entered by the arbitrator.
                </Terms.Text>
                <Terms.Text>
                  If for any reason, a Dispute proceeds in court rather than
                  arbitration, the Dispute shall be commenced or prosecuted in
                  the state and federal courts located in Pennsylvania, and the
                  Parties hereby consent to, and waive all defenses of lack of
                  personal jurisdiction, and forum non conveniens with respect
                  to venue and jurisdiction in such state and federal courts.
                  Application of the United Nations Convention on Contracts for
                  the International Sale of Goods and the Uniform Computer
                  Information Transaction Act (UCITA) is excluded from these
                  Terms of Use.
                </Terms.Text>
                <Terms.Text>
                  In no event shall any Dispute brought by either Party related
                  in any way to the Services be commenced more than one (1)
                  years after the cause of action arose. If this provision is
                  found to be illegal or unenforceable, then neither Party will
                  elect to arbitrate any Dispute falling within that portion of
                  this provision found to be illegal or unenforceable and such
                  Dispute shall be decided by a court of competent jurisdiction
                  within the courts listed for jurisdiction above, and the
                  Parties agree to submit to the personal jurisdiction of that
                  court.
                </Terms.Text>
                <Terms.Subtitle>Restrictions</Terms.Subtitle>
                <Terms.Text>
                  The Parties agree that any arbitration shall be limited to the
                  Dispute between the Parties individually. To the full extent
                  permitted by law, (a) no arbitration shall be joined with any
                  other proceeding; (b) there is no right or authority for any
                  Dispute to be arbitrated on a class-action basis or to utilize
                  class action procedures; and (c) there is no right or
                  authority for any Dispute to be brought in a purported
                  representative capacity on behalf of the general public or any
                  other persons.
                </Terms.Text>
                <Terms.Subtitle>
                  Exceptions to Informal Negotiations and Arbitration
                </Terms.Subtitle>
                <Terms.Text>
                  The Parties agree that the following Disputes are not subject
                  to the above provisions concerning informal negotiations
                  binding arbitration: (a) any Disputes seeking to enforce or
                  protect, or concerning the validity of, any of the
                  intellectual property rights of a Party; (b) any Dispute
                  related to, or arising from, allegations of theft, piracy,
                  invasion of privacy, or unauthorized use; and (c) any claim
                  for injunctive relief. If this provision is found to be
                  illegal or unenforceable, then neither Party will elect to
                  arbitrate any Dispute falling within that portion of this
                  provision found to be illegal or unenforceable and such
                  Dispute shall be decided by a court of competent jurisdiction
                  within the courts listed for jurisdiction above, and the
                  Parties agree to submit to the personal jurisdiction of that
                  court.
                </Terms.Text>
              </Terms.ContentBox>
              <Terms.ContentBox id="corrections">
                <Terms.TitleSmall as="h5">17. CORRECTIONS</Terms.TitleSmall>
                <Terms.Text>
                  There may be information on the Site that contains
                  typographical errors, inaccuracies, or omissions, including
                  descriptions, pricing, availability, and various other
                  information. We reserve the right to correct any errors,
                  inaccuracies, or omissions and to change or update the
                  information on the Site at any time, without prior notice.
                </Terms.Text>
              </Terms.ContentBox>
              <Terms.ContentBox id="disclaimer">
                <Terms.TitleSmall as="h5">18. DISCLAIMER</Terms.TitleSmall>
                <Terms.Text>
                  THE SERVICES OF MONSAI, INCLUDING BUT NOT LIMITED TO LESSONS,
                  QUIZZES, TASKS, RESOURCES, TIPS, AND AI-GENERATED CONTENT, ARE
                  PROVIDED ON AN AS-IS AND AS-AVAILABLE BASIS. YOU AGREE THAT
                  YOUR USE OF THE SERVICES WILL BE AT YOUR SOLE RISK. TO THE
                  FULLEST EXTENT PERMITTED BY LAW, WE DISCLAIM ALL WARRANTIES,
                  EXPRESS OR IMPLIED, IN CONNECTION WITH THE SERVICES AND YOUR
                  USE THEREOF, INCLUDING, WITHOUT LIMITATION, THE IMPLIED
                  WARRANTIES OF MERCHANTABILITY, FITNESS FOR A PARTICULAR
                  PURPOSE, AND NON-INFRINGEMENT. WE MAKE NO WARRANTIES OR
                  REPRESENTATIONS ABOUT THE ACCURACY, COMPLETENESS, RELIABILITY,
                  OR TIMELINESS OF THE SERVICES' CONTENT, AI-GENERATED MATERIAL,
                  OR THE CONTENT OF ANY WEBSITES OR MOBILE APPLICATIONS LINKED
                  TO THE SERVICES.
                </Terms.Text>
                <Terms.Text>
                  WE ASSUME NO LIABILITY OR RESPONSIBILITY FOR ANY (1) ERRORS,
                  MISTAKES, OR INACCURACIES OF CONTENT AND MATERIALS, INCLUDING
                  THOSE GENERATED BY AI; (2) PERSONAL INJURY OR PROPERTY DAMAGE,
                  OF ANY NATURE WHATSOEVER, RESULTING FROM YOUR ACCESS TO AND
                  USE OF THE SERVICES; (3) ANY UNAUTHORIZED ACCESS TO OR USE OF
                  OUR SECURE SERVERS AND/OR ANY AND ALL PERSONAL INFORMATION
                  AND/OR FINANCIAL INFORMATION STORED THEREIN; (4) INTERRUPTION
                  OR CESSATION OF TRANSMISSION TO OR FROM THE SERVICES; (5) ANY
                  BUGS, VIRUSES, OR OTHER HARMFUL COMPONENTS TRANSMITTED TO OR
                  THROUGH THE SERVICES BY ANY THIRD PARTY; AND/OR (6) ERRORS OR
                  OMISSIONS IN ANY CONTENT AND MATERIALS OR FOR ANY LOSS OR
                  DAMAGE INCURRED AS A RESULT OF THE USE OF ANY CONTENT POSTED,
                  TRANSMITTED, OR OTHERWISE MADE AVAILABLE VIA THE SERVICES.
                </Terms.Text>
                <Terms.Text>
                  WE DO NOT WARRANT, ENDORSE, GUARANTEE, OR ASSUME
                  RESPONSIBILITY FOR ANY PRODUCT OR SERVICE ADVERTISED OR
                  OFFERED BY A THIRD PARTY THROUGH THE SERVICES, ANY HYPERLINKED
                  WEBSITE, OR ANY WEBSITE OR MOBILE APPLICATION FEATURED IN ANY
                  BANNER OR OTHER ADVERTISING. WE WILL NOT BE A PARTY TO OR IN
                  ANY WAY BE RESPONSIBLE FOR MONITORING ANY TRANSACTION BETWEEN
                  YOU AND THIRD-PARTY PROVIDERS OF PRODUCTS OR SERVICES. THE
                  INFORMATION PROVIDED BY MONSAI IS NOT A SUBSTITUTE FOR
                  PROFESSIONAL ADVICE AND SHOULD BE USED FOR PERSONAL
                  DEVELOPMENT AND SELF-IMPROVEMENT PURPOSES ONLY. ALWAYS
                  EXERCISE CAUTION AND USE YOUR BEST JUDGMENT IN RELIANCE ON ANY
                  CONTENT PROVIDED BY THE SERVICES.
                </Terms.Text>
              </Terms.ContentBox>
              <Terms.ContentBox id="liability">
                <Terms.TitleSmall as="h5">
                  19. LIMITATIONS OF LIABILITY
                </Terms.TitleSmall>
                <Terms.Text>
                  IN NO EVENT WILL WE OR OUR DIRECTORS, EMPLOYEES, OR AGENTS BE
                  LIABLE TO YOU OR ANY THIRD PARTY FOR ANY DIRECT, INDIRECT,
                  CONSEQUENTIAL, EXEMPLARY, INCIDENTAL, SPECIAL, OR PUNITIVE
                  DAMAGES, INCLUDING LOST PROFIT, LOST REVENUE, LOSS OF DATA, OR
                  OTHER DAMAGES ARISING FROM YOUR USE OF THE SERVICES, EVEN IF
                  WE HAVE BEEN ADVISED OF THE POSSIBILITY OF SUCH DAMAGES.
                  NOTWITHSTANDING ANYTHING TO THE CONTRARY CONTAINED HEREIN, OUR
                  LIABILITY TO YOU FOR ANY CAUSE WHATSOEVER AND REGARDLESS OF
                  THE FORM OF THE ACTION, WILL AT ALL TIMES BE LIMITED TO THE
                  AMOUNT PAID, IF ANY, BY YOU TO US DURING THE TWELVE (12) MONTH
                  PERIOD PRIOR TO ANY CAUSE OF ACTION ARISING. CERTAIN US STATE
                  LAWS AND INTERNATIONAL LAWS DO NOT ALLOW LIMITATIONS ON
                  IMPLIED WARRANTIES OR THE EXCLUSION OR LIMITATION OF CERTAIN
                  DAMAGES. IF THESE LAWS APPLY TO YOU, SOME OR ALL OF THE ABOVE
                  DISCLAIMERS OR LIMITATIONS MAY NOT APPLY TO YOU, AND YOU MAY
                  HAVE ADDITIONAL RIGHTS.
                </Terms.Text>
              </Terms.ContentBox>
              <Terms.ContentBox id="indemnification">
                <Terms.TitleSmall as="h5">20. INDEMNIFICATION</Terms.TitleSmall>
                <Terms.Text>
                  You agree to defend, indemnify, and hold us harmless,
                  including our subsidiaries, affiliates, and all of our
                  respective officers, agents, partners, and employees, from and
                  against any loss, damage, liability, claim, or demand,
                  including reasonable attorneys’ fees and expenses, made by any
                  third party due to or arising out of: (1) use of the Services;
                  (2) breach of these Legal Terms; (3) any breach of your
                  representations and warranties set forth in these Legal Terms;
                  (4) your violation of the rights of a third party, including
                  but not limited to intellectual property rights; or (5) any
                  overt harmful act toward any other user of the Services with
                  whom you connected via the Services. Notwithstanding the
                  foregoing, we reserve the right, at your expense, to assume
                  the exclusive defense and control of any matter for which you
                  are required to indemnify us, and you agree to cooperate, at
                  your expense, with our defense of such claims. We will use
                  reasonable efforts to notify you of any such claim, action, or
                  proceeding which is subject to this indemnification upon
                  becoming aware of it.
                </Terms.Text>
              </Terms.ContentBox>
              <Terms.ContentBox id="userdata">
                <Terms.TitleSmall as="h5">21. USER DATA</Terms.TitleSmall>
                <Terms.Text>
                  We will maintain certain data that you transmit to the
                  Services for the purpose of managing the performance of the
                  Services, as well as data relating to your use of the
                  Services. Although we perform regular routine backups of data,
                  you are solely responsible for all data that you transmit or
                  that relates to any activity you have undertaken using the
                  Services. You agree that we shall have no liability to you for
                  any loss or corruption of any such data, and you hereby waive
                  any right of action against us arising from any such loss or
                  corruption of such data.
                </Terms.Text>
              </Terms.ContentBox>
              <Terms.ContentBox id="electronic">
                <Terms.TitleSmall as="h5">
                  22. ELECTRONIC COMMUNICATIONS, TRANSACTIONS, AND SIGNATURES
                </Terms.TitleSmall>
                <Terms.Text>
                  Visiting the Services, sending us emails, and completing
                  online forms constitute electronic communications. You consent
                  to receive electronic communications, and you agree that all
                  agreements, notices, disclosures, and other communications we
                  provide to you electronically, via email and on the Services,
                  satisfy any legal requirement that such communication be in
                  writing. YOU HEREBY AGREE TO THE USE OF ELECTRONIC SIGNATURES,
                  CONTRACTS, ORDERS, AND OTHER RECORDS, AND TO ELECTRONIC
                  DELIVERY OF NOTICES, POLICIES, AND RECORDS OF TRANSACTIONS
                  INITIATED OR COMPLETED BY US OR VIA THE SERVICES. You hereby
                  waive any rights or requirements under any statutes,
                  regulations, rules, ordinances, or other laws in any
                  jurisdiction which require an original signature or delivery
                  or retention of non-electronic records, or to payments or the
                  granting of credits by any means other than electronic means.
                </Terms.Text>
              </Terms.ContentBox>
              <Terms.ContentBox id="california">
                <Terms.TitleSmall as="h5">
                  23. CALIFORNIA USERS AND RESIDENTS
                </Terms.TitleSmall>
                <Terms.Text>
                  If any complaint with us is not satisfactorily resolved, you
                  can contact the Complaint Assistance Unit of the Division of
                  Consumer Services of the California Department of Consumer
                  Affairs in writing at 1625 North Market Blvd., Suite N 112,
                  Sacramento, California 95834 or by telephone at (800) 952-5210
                  or (916) 445-1254.
                </Terms.Text>
              </Terms.ContentBox>
              <Terms.ContentBox id="miscellaneous">
                <Terms.TitleSmall as="h5">24. MISCELLANEOUS</Terms.TitleSmall>
                <Terms.Text>
                  These Legal Terms and any policies or operating rules posted
                  by us on the Services or in respect to the Services constitute
                  the entire agreement and understanding between you and us. Our
                  failure to exercise or enforce any right or provision of these
                  Legal Terms shall not operate as a waiver of such right or
                  provision. These Legal Terms operate to the fullest extent
                  permissible by law. We may assign any or all of our rights and
                  obligations to others at any time. We shall not be responsible
                  or liable for any loss, damage, delay, or failure to act
                  caused by any cause beyond our reasonable control. If any
                  provision or part of a provision of these Legal Terms is
                  determined to be unlawful, void, or unenforceable, that
                  provision or part of the provision is deemed severable from
                  these Legal Terms and does not affect the validity and
                  enforceability of any remaining provisions. There is no joint
                  venture, partnership, employment or agency relationship
                  created between you and us as a result of these Legal Terms or
                  use of the Services. You agree that these Legal Terms will not
                  be construed against us by virtue of having drafted them. You
                  hereby waive any and all defenses you may have based on the
                  electronic form of these Legal Terms and the lack of signing
                  by the parties hereto to execute these Legal Terms.
                </Terms.Text>
              </Terms.ContentBox>
              <Terms.ContentBox id="contact">
                <Terms.TitleSmall as="h5">25. CONTACT US</Terms.TitleSmall>
                <Terms.Text>
                  In order to resolve a complaint regarding the Site or to
                  receive further information regarding use of the Site, please
                  contact us:
                </Terms.Text>
                <Terms.List>
                  <Terms.ListItem>
                    By Email:{" "}
                    <a href="mailto: support@monsaiapp.com">
                      support@monsaiapp.com
                    </a>
                  </Terms.ListItem>
                </Terms.List>
              </Terms.ContentBox>
            </Terms.Content>
          </Col>
        </Row>
      </Container>
    </Terms>
  )
}
